import {
  IMediaRecorder,
  MediaRecorder,
  register
} from 'extendable-media-recorder';
import { connect } from 'extendable-media-recorder-wav-encoder';
import React from 'react';
import {
  ConversationStatus,
  SelfHostedConversationConfig
} from '../types/conversation';
import { blobToBase64, stringify } from '../utils';
import { AudioEncoding } from '../types/audioEncoding';
import {
  AudioConfigStartMessage,
  AudioMessage,
  FullAudioMessage,
  StopMessage
} from '../types/websocket';
import { Buffer } from 'buffer';

const DEFAULT_CHUNK_SIZE = 2048;
let counter = 0;

export const useConversation = (
  backendUrl: string
): {
  status: ConversationStatus;
  start: () => void;
  stop: () => void;
  toggleActive: () => void;
  active: boolean;
  setActive: React.Dispatch<React.SetStateAction<boolean>>;
  error: Error | undefined;
  analyserNode: AnalyserNode | undefined;
} => {
  const [audioContext, setAudioContext] = React.useState<AudioContext>();
  const [audioAnalyser, setAudioAnalyser] = React.useState<AnalyserNode>();
  const [audioQueue, setAudioQueue] = React.useState<ArrayBuffer[]>([]);
  const [processing, setProcessing] = React.useState(false);
  const [userVoiceRecorder, setUserVoiceRecorder] =
    React.useState<IMediaRecorder>();
  const [fullAudioRecorder, setFullAudioRecorder] =
    React.useState<IMediaRecorder>();
  const [fullAudioDestinationNode, setFullAudioDestinationNode] =
    React.useState<MediaStreamAudioDestinationNode>();

  const [socket, setSocket] = React.useState<WebSocket>();
  const [status, setStatus] = React.useState<ConversationStatus>('idle');
  const [error, setError] = React.useState<Error>();
  const [active, setActive] = React.useState(false);
  const toggleActive = () => setActive(!active);
  const [nextStartTime, setNextStartTime] = React.useState(0);

  /** Recorder Event Listeners */
  const recordingDataListener = ({ data }: { data: Blob }) => {
    blobToBase64(data).then((base64Encoded: string | null) => {
      if (!base64Encoded) return;
      const audioMessage: AudioMessage = {
        type: 'websocket_audio',
        data: base64Encoded
      };
      socket!.readyState === WebSocket.OPEN &&
        socket!.send(stringify(audioMessage));
    });
  };

  const recordingFullDataListener = ({ data }: { data: Blob }) => {
    const audioCounter = counter;
    counter = counter + 1;
    blobToBase64(data).then((base64Encoded: string | null) => {
      if (!base64Encoded) return;
      const audioMessage: FullAudioMessage = {
        type: 'websocket_full_audio',
        data: base64Encoded,
        count: audioCounter
      };
      socket!.readyState === WebSocket.OPEN &&
        socket!.send(stringify(audioMessage));
    });
  };

  /** Create AudioContext, AudioAnalyzer and Recorders on mount */
  React.useEffect(() => {
    const audioContext = new AudioContext();
    setAudioContext(audioContext);

    const audioAnalyser = audioContext.createAnalyser();
    setAudioAnalyser(audioAnalyser);
  }, []);

  React.useEffect(() => {
    console.log(fullAudioRecorder, active);
    if (!fullAudioRecorder) return;
    fullAudioRecorder.ondataavailable = recordingFullDataListener;
    fullAudioRecorder.onstop = () => {
      fullAudioRecorder.ondataavailable = null;
    };
  }, [fullAudioRecorder, active]);

  React.useEffect(() => {
    console.log(fullAudioRecorder, active);
    if (!userVoiceRecorder) return;
    userVoiceRecorder.ondataavailable = recordingDataListener;
    userVoiceRecorder.onstop = () => {
      userVoiceRecorder.ondataavailable = null;
    };
  }, [userVoiceRecorder, active]);

  // accept wav audio from webpage
  React.useEffect(() => {
    const registerWav = async () => {
      await register(await connect());
    };
    registerWav().catch(console.error);
  }, []);

  /** Setup processing of queue messages received from backend on the websocket.
   * We need AudioContext to play the audio received from backend.
   */
  React.useEffect(() => {
    const playArrayBuffer = (arrayBuffer: ArrayBuffer) => {
      // check if audioContext and audioAnalyzer exist
      if (
        audioContext == null ||
        audioAnalyser == null ||
        fullAudioDestinationNode == null
      ) {
        return;
      }

      audioContext.decodeAudioData(arrayBuffer, (buffer) => {
        const source = audioContext.createBufferSource();
        source.buffer = buffer;

        source.onended = () => {
          setProcessing(false);
        };

        const duration = buffer.duration;
        const currentTime = audioContext.currentTime;
        const scheduledTime = Math.max(nextStartTime, currentTime);

        // Use a gain node for a smooth transition
        const gainNode = audioContext.createGain();
        source.connect(gainNode);
        gainNode.connect(audioContext.destination);
        gainNode.connect(fullAudioDestinationNode); // connect backend audio to destination

        source.start(scheduledTime);

        setNextStartTime(scheduledTime + duration);
      });
    };
    if (!processing && audioQueue.length > 0) {
      setProcessing(true);
      const audio = audioQueue.shift();

      // @ts-ignore
      const audioBuffer = Buffer.from(audio, 'base64');
      if (audioBuffer) {
        fetch(URL.createObjectURL(new Blob([audioBuffer])))
          .then((response) => response.arrayBuffer())
          .then(playArrayBuffer);
      }
    }
  }, [audioQueue, processing]);

  const stopConversation = (error?: Error) => {
    setAudioQueue([]);
    setActive(false);
    if (error) {
      setError(error);
      setStatus('error');
    } else {
      setStatus('idle');
    }

    if (!userVoiceRecorder || !socket) return;
    userVoiceRecorder.stop();
    if (!fullAudioRecorder || !socket) return;
    fullAudioRecorder.stop();

    const stopMessage: StopMessage = {
      type: 'websocket_stop'
    };
    socket.send(stringify(stopMessage));
    socket.close();
  };

  const startConversation = async () => {
    if (!audioContext || !audioAnalyser) return;
    setStatus('connecting');

    if (audioContext.state === 'suspended') {
      audioContext.resume();
    }

    /**
     * Setup Audio Tracks
     * 1. `userMicStream` captures user mic input and sends it to the backend
     * 2. `fullAudioMicStream` captures user mic input and combines it agent response from backend
     */
    const userMicTrackConstraints: MediaTrackConstraints = {
      echoCancellation: true
    };
    const userMicStream = await navigator.mediaDevices.getUserMedia({
      video: false,
      audio: userMicTrackConstraints
    });

    const fullAudioMicTrackConstraints: MediaTrackConstraints = {
      echoCancellation: false,
      sampleRate: 48000
    };
    const fullAudioMicStream = await navigator.mediaDevices.getUserMedia({
      video: false,
      audio: fullAudioMicTrackConstraints
    });

    const fullAudioMicSource =
      audioContext.createMediaStreamSource(fullAudioMicStream);
    const fullAudioDestination = audioContext.createMediaStreamDestination();
    fullAudioMicSource.connect(fullAudioDestination); // connect user mic to destination

    // Make this object available elsewhere
    setFullAudioDestinationNode(fullAudioDestination);

    let recorderToUseForUserVoice = userVoiceRecorder;
    if (
      recorderToUseForUserVoice &&
      recorderToUseForUserVoice.state === 'paused'
    ) {
      recorderToUseForUserVoice.resume();
    } else if (!recorderToUseForUserVoice) {
      recorderToUseForUserVoice = new MediaRecorder(userMicStream, {
        mimeType: 'audio/wav'
      });
      setUserVoiceRecorder(recorderToUseForUserVoice);
    }

    let recorderToUseForFullAudio = fullAudioRecorder;
    if (
      recorderToUseForFullAudio &&
      recorderToUseForFullAudio.state === 'paused'
    ) {
      recorderToUseForFullAudio.resume();
    } else if (!recorderToUseForFullAudio) {
      recorderToUseForFullAudio = new MediaRecorder(
        fullAudioDestination.stream,
        {
          mimeType: 'audio/wav'
        }
      );
      setFullAudioRecorder(recorderToUseForFullAudio);
    }

    /** Setup socket
     * 1. Setup event listeners
     * 2. Wait for socket to get ready
     * 3. Send start message to receive audio from the backend
     */
    const socket = new WebSocket(backendUrl);
    let error: Error | undefined;

    socket.onerror = (event) => {
      console.error(event);
      error = new Error('See console for error details');
    };

    socket.onmessage = (event) => {
      const message = JSON.parse(event.data);
      if (message.type === 'websocket_audio') {
        setAudioQueue((prev) => [...prev, message.data]);
      } else if (message.type === 'websocket_ready') {
        setStatus('connected');
        setActive(true);
      }
    };

    socket.onclose = () => {
      stopConversation(error);
    };

    setSocket(socket);

    // wait for socket to be ready
    await new Promise((resolve) => {
      const interval = setInterval(() => {
        if (socket.readyState === WebSocket.OPEN) {
          clearInterval(interval);
          resolve(null);
        }
      }, 100);
    });

    const micSettings = userMicStream.getAudioTracks()[0].getSettings();

    const inputAudioMetadata = {
      samplingRate: micSettings.sampleRate || audioContext.sampleRate,
      audioEncoding: 'linear16' as AudioEncoding
    };
    console.log('Input audio metadata', inputAudioMetadata);

    const outputAudioMetadata = {
      samplingRate: audioContext.sampleRate,
      audioEncoding: 'linear16' as AudioEncoding
    };
    console.log('Output audio metadata', inputAudioMetadata);

    let config = {};
    const selfHostedConversationConfig = config as SelfHostedConversationConfig;

    const startMessage = getAudioConfigStartMessage(
      inputAudioMetadata,
      outputAudioMetadata,
      selfHostedConversationConfig.chunkSize,
      selfHostedConversationConfig.downsampling,
      selfHostedConversationConfig.conversationId,
      selfHostedConversationConfig.subscribeTranscript,
      selfHostedConversationConfig.payload
    );

    socket.send(stringify(startMessage));
    console.log('Access to microphone granted');
    console.log(startMessage);

    /**
     * Start the recorders to record:
     * 1. recorderToUseForUserVoice records and sends user Mic Audio to backend
     * 2. recorderToUseForFullAudio listens both backend and mic audio, and sends it to backend
     */
    let timeSlice = 20;
    if (recorderToUseForUserVoice.state === 'recording') {
      return;
    }
    recorderToUseForUserVoice.start(timeSlice);

    if (recorderToUseForFullAudio.state === 'recording') {
      return;
    }
    recorderToUseForFullAudio.start(timeSlice);
  };

  return {
    status,
    start: startConversation,
    stop: stopConversation,
    error,
    toggleActive,
    active,
    setActive,
    analyserNode: audioAnalyser
  };
};

const getAudioConfigStartMessage = (
  inputAudioMetadata: { samplingRate: number; audioEncoding: AudioEncoding },
  outputAudioMetadata: { samplingRate: number; audioEncoding: AudioEncoding },
  chunkSize: number | undefined,
  downsampling: number | undefined,
  conversationId: string | undefined,
  subscribeTranscript: boolean | undefined,
  payload: any | undefined
): AudioConfigStartMessage => ({
  type: 'websocket_audio_config_start',
  inputAudioConfig: {
    samplingRate: inputAudioMetadata.samplingRate,
    audioEncoding: inputAudioMetadata.audioEncoding,
    chunkSize: chunkSize || DEFAULT_CHUNK_SIZE,
    downsampling
  },
  outputAudioConfig: {
    samplingRate: outputAudioMetadata.samplingRate,
    audioEncoding: outputAudioMetadata.audioEncoding
  },
  conversationId,
  subscribeTranscript,
  payload
});
