import { createFileRoute, redirect } from '@tanstack/react-router';
import { getAuth } from 'firebase/auth';
import { Slider, Switch } from '@mui/material';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import * as queries from '../queries';
import React, { useState, useEffect, useRef, SetStateAction } from 'react';
import { AgentConfig, AgentModels, FillerAudios } from '../types/api';

export const Route = createFileRoute('/agent')({
  beforeLoad: async ({ location }) => {
    if (!getAuth().currentUser) {
      throw redirect({
        to: '/login'
      });
    }
  },
  component: AgentManager
});

export default function AgentManager() {
  const [selectedAgent, setSelectedAgent] = useState<AgentConfig | null>(null);

  const queryClient = useQueryClient();
  const { data: new_agents, isSuccess: getAgentsIsSuccess } = useQuery({
    queryKey: ['getAllAgents'],
    queryFn: queries.getAllAgents
  });

  const handleSelectAgent = (agent: AgentConfig) => {
    setSelectedAgent(agent);
  };

  const addMutation = useMutation({
    mutationFn: queries.createDefaultAgent,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getAllAgents'] });
    }
  });

  const handleAddAgent = () => {
    const query = addMutation.mutate();
  };

  const saveMutation = useMutation({
    mutationFn: queries.patchAgent,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getAllAgents'] });
    }
  });

  const handleSaveAgent = (agent: AgentConfig) => {
    const query = saveMutation.mutate(agent);
  };

  const deleteMutation = useMutation({
    mutationFn: queries.deleteAgent,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getAllAgents'] });
    }
  });

  const handleDeleteAgent = (agent: AgentConfig) => {
    const query = deleteMutation.mutate(agent.agent_id, {
      onSuccess: (data, agent_id, context) => {
        if (selectedAgent && agent_id == selectedAgent.agent_id) {
          setSelectedAgent(null);
        }
        queryClient.invalidateQueries({ queryKey: ['getAllAgents'] });
      }
    });
  };

  const { data: voices, isSuccess: getVoicesIsSuccess } = useQuery({
    queryKey: ['getAllVoices'],
    queryFn: queries.getAllVoices
  });

  const { data: models, isSuccess: getModelsIsSuccess } = useQuery({
    queryKey: ['getAllModels'],
    queryFn: queries.getAllModels
  });

  const { data: languages, isSuccess: getLanguagesIsSuccess } = useQuery({
    queryKey: ['getAllLanguages'],
    queryFn: queries.getAllLanguages
  });

  const { data: fillerAudios, isSuccess: getFillerAudiosIsSuccess } = useQuery({
    queryKey: ['getAllFillerAudios'],
    queryFn: queries.getAllFillerAudios
  });

  return (
    <div className="flex">
      {getAgentsIsSuccess &&
        getVoicesIsSuccess &&
        getModelsIsSuccess &&
        getLanguagesIsSuccess &&
        getFillerAudiosIsSuccess && (
          <>
            <Sidebar
              agents={new_agents}
              onSelectAgent={handleSelectAgent}
              onAddAgent={handleAddAgent}
            />
            <div className="w-3/4 p-4">
              {selectedAgent ? (
                <div>
                  <Agent
                    key={selectedAgent.agent_id}
                    selectedAgent={selectedAgent}
                    onSaveAgent={handleSaveAgent}
                    onDeleteAgent={handleDeleteAgent}
                    voices={voices}
                    models={models}
                    languages={languages}
                    fillerAudios={fillerAudios}
                  />
                </div>
              ) : (
                <p>Select an agent from the list.</p>
              )}
            </div>
          </>
        )}
    </div>
  );
}

interface SidebarProps {
  agents: AgentConfig[];
  onSelectAgent: (agent: AgentConfig) => void;
  onAddAgent: () => void; // Define the type for onAddAgent here
}
const Sidebar = ({ agents, onSelectAgent, onAddAgent }: SidebarProps) => {
  return (
    <div className="w-1/4 bg-gray-800 text-white min-h-screen">
      <h2 className="text-xl font-bold p-4">Agents</h2>
      <ul>
        <li
          onClick={onAddAgent}
          className="px-4 py-2 hover:bg-gray-700 cursor-pointer">
          + Create New Agent
        </li>
        {agents.map((agent) => (
          <li
            key={agent.agent_id}
            className="px-4 py-2 hover:bg-gray-700 cursor-pointer"
            onClick={() => onSelectAgent(agent)}>
            {agent.name}
          </li>
        ))}
      </ul>
    </div>
  );
};

const allowed_actions = [
  {
    name: 'Order Status (BigCommerce)',
    type: 'order-status',
    description: 'Fetches order status from BigCommerce'
  }
];

interface AgentProps {
  selectedAgent: AgentConfig;
  onSaveAgent: (agent: AgentConfig) => void;
  onDeleteAgent: (agent: AgentConfig) => void;
  voices: string[];
  models: string[];
  languages: string[];
  fillerAudios: string[];
}

function Agent({
  selectedAgent,
  onSaveAgent,
  onDeleteAgent,
  voices,
  models,
  languages,
  fillerAudios
}: AgentProps) {
  const [agentConfig, setAgentConfig] = useState({ ...selectedAgent });

  const [backgroundNoise, setBackgroundNoise] = useState('');
  const [responsiveness, setResponsiveness] = useState(0.5);
  const [voiceTemperature, setVoiceTemperature] = useState(0.5);
  const [boostedKeywords, setBoostedKeywords] = useState('');

  const [showModal, setShowModal] = useState(false);
  const [newDataSource, setNewDataSource] = useState({
    type: '',
    name: '',
    url: ''
  });

  return (
    <>
      <div className="mt-6 flex items-center justify-end gap-x-6">
        <button
          onClick={() => onDeleteAgent(agentConfig)}
          type="button"
          className="text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700">
          Delete
        </button>

        <button
          onClick={() => onSaveAgent(agentConfig)}
          className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
          type="button">
          Save
        </button>
      </div>

      <div>
        <div className="grid grid-cols-1 px-4 pt-6 xl:grid-cols-3 xl:gap-4 dark:bg-gray-900" />
        <div className="col-span-2">
          {/* Basic Agent Settings  */}
          <div className="p-4 mb-4 bg-white border border-gray-200 rounded-lg shadow-sm 2xl:col-span-2 dark:border-gray-700 sm:p-6 dark:bg-gray-800">
            <h2 className="mb-4 text-xl font-bold text-gray-900 dark:text-white">
              Basic Settings
            </h2>

            <form action="#">
              <div className="grid gap-4 sm:grid-cols-2 sm:gap-6">
                <div className="sm:col-span-2">
                  <label
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    htmlFor="agentName">
                    Agent Name
                  </label>
                  <input
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    type="text"
                    value={agentConfig.name}
                    onChange={(e) => {
                      setAgentConfig({
                        ...agentConfig,
                        ['name']: e.target.value
                      });
                    }}
                    name="agentName"
                    id="agentName"
                    autoComplete="agentName"
                    placeholder="Agent Name"
                    required
                  />

                  <p className="mt-1 text-sm leading-6 text-gray-600">
                    Agent ID: {agentConfig.agent_id}
                  </p>
                </div>
                <div className="w-full">
                  <label
                    htmlFor="voice"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                    Select Agent Voice
                  </label>
                  <select
                    value={agentConfig.voice_settings.voice_id}
                    onChange={(e) =>
                      setAgentConfig({
                        ...agentConfig,
                        ['voice_settings']: {
                          ...agentConfig.voice_settings,
                          ['voice_id']: e.target.value
                        }
                      })
                    }
                    name="voice"
                    autoComplete="voice-id"
                    id="voice"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                    {voices.map((voice) => (
                      <option value={voice} key={voice}>
                        {voice}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="w-full">
                  <label
                    htmlFor="models"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                    Select Model
                  </label>
                  <select
                    value={agentConfig.agent_settings.model_id}
                    onChange={(e) =>
                      setAgentConfig({
                        ...agentConfig,
                        agent_settings: {
                          ...agentConfig.agent_settings,
                          model_id: e.target.value as AgentModels
                        }
                      })
                    }
                    name="models"
                    autoComplete="model-id"
                    id="models"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                    {models.map((model) => (
                      <option value={model} key={model}>
                        {model}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="w-full">
                  <label
                    htmlFor="language"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                    Select Language
                  </label>
                  <select
                    value={agentConfig.language}
                    onChange={(e) =>
                      setAgentConfig({
                        ...agentConfig,
                        language: e.target.value
                      })
                    }
                    name="language"
                    autoComplete="language-id"
                    id="language"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                    {languages.map((language) => (
                      <option value={language} key={language}>
                        {language}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="sm:col-span-2">
                  <label
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    htmlFor="greetingMessage">
                    Greeting Message
                  </label>
                  <input
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    type="text"
                    value={agentConfig.agent_settings.greeting}
                    onChange={(e) =>
                      setAgentConfig({
                        ...agentConfig,
                        ['agent_settings']: {
                          ...agentConfig.agent_settings,
                          ['greeting']: e.target.value
                        }
                      })
                    }
                    name="greeting"
                    id="greeting"
                    autoComplete="greeting"
                    placeholder="Greeting Message"
                    required
                  />
                </div>
                <div className="sm:col-span-2">
                  <label
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    htmlFor="prompt">
                    LLM Prompt
                  </label>
                  <textarea
                    className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    id="prompt"
                    value={agentConfig.agent_settings.system_prompt}
                    onChange={(e) =>
                      setAgentConfig({
                        ...agentConfig,
                        ['agent_settings']: {
                          ...agentConfig.agent_settings,
                          ['system_prompt']: e.target.value
                        }
                      })
                    }
                    name="prompt"
                    placeholder="Your prompt here"
                    rows={12}
                  />
                </div>
              </div>
            </form>
          </div>

          {/* Voice Settings */}
          <div className="p-4 mb-4 bg-white border border-gray-200 rounded-lg shadow-sm 2xl:col-span-2 dark:border-gray-700 sm:p-6 dark:bg-gray-800">
            <div className="mb-2">
              <h2 className="text-xl font-bold text-gray-900 dark:text-white">
                Voice Settings
              </h2>
              <p className="text-sm leading-6 text-gray-600">
                Refer to API docs to learn more.
              </p>
            </div>

            <form action="#">
              <div className="grid gap-4 grid-cols-2 sm:grid-cols-2 sm:gap-6">
                <div className="w-full">
                  <label
                    htmlFor="background-noise"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                    Select Background Noise
                  </label>
                  <select
                    name="background-noise"
                    id="background-noise"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                    <option value="background-noise-coffee-shop">
                      Coffee Shop
                    </option>
                    <option value="background-noise-outdoors">Outdoors</option>
                    <option value="background-noise-traffic">Traffic</option>
                  </select>
                </div>
                <div className="w-full">
                  <label
                    htmlFor="voice-speed"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                    Voice Speed
                  </label>
                  <input
                    id="voice-speed"
                    type="range"
                    value={agentConfig.voice_settings.speed}
                    onChange={(e) =>
                      setAgentConfig({
                        ...agentConfig,
                        voice_settings: {
                          ...agentConfig.voice_settings,
                          speed: Number(e.target.value)
                        }
                      })
                    }
                    step={0.1}
                    min={0}
                    max={1}
                    className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700"
                  />
                </div>
                <div className="w-full">
                  <label
                    htmlFor="voice-stability"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                    Voice Stability
                  </label>

                  <input
                    id="voice-stability"
                    type="range"
                    value={agentConfig.voice_settings.stability}
                    onChange={(e) =>
                      setAgentConfig({
                        ...agentConfig,
                        voice_settings: {
                          ...agentConfig.voice_settings,
                          stability: Number(e.target.value)
                        }
                      })
                    }
                    aria-labelledby="voice-stabiity-slider"
                    step={0.1}
                    min={0}
                    max={1}
                    className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700"
                  />
                </div>
                <div className="w-full">
                  <label
                    htmlFor="voice-similarity"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                    Voice Similarity
                  </label>
                  <input
                    id="voice-similarity"
                    type="range"
                    value={agentConfig.voice_settings.similarity}
                    onChange={(e) =>
                      setAgentConfig({
                        ...agentConfig,
                        voice_settings: {
                          ...agentConfig.voice_settings,
                          similarity: Number(e.target.value)
                        }
                      })
                    }
                    step={0.1}
                    min={0}
                    max={1}
                    className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700"
                  />
                </div>
                <div className="w-full">
                  <label
                    htmlFor="voice-temperature"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                    Voice Temperature
                  </label>

                  <input
                    readOnly
                    id="voice-temperature"
                    type="range"
                    value={0.4}
                    aria-labelledby="voice-stabiity-slider"
                    step={0.1}
                    min={0}
                    max={1}
                    className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700"
                  />
                </div>
                <div className="sm:col-span-2">
                  <label
                    htmlFor="voice-responsiveness"
                    className="block text-sm font-medium leading-6 text-gray-900">
                    Voice Responsiveness
                  </label>
                  <div className="mt-2">
                    <p>Coming soon.</p>
                    {/* <Slider
             value={responsiveness}
             onChange={(e, newValue) => setResponsiveness(newValue as number)}
             aria-labelledby="voice-responsiveness-slider"
             valueLabelDisplay="auto"
             step={0.1}
             marks
             min={0}
             max={1}
           /> */}
                  </div>
                </div>
                <div className="sm:col-span-2">
                  <label
                    htmlFor="boostedKeywords"
                    className="block text-sm font-medium leading-6 text-gray-900">
                    Boosted Keywords
                  </label>
                  <div className="mt-2">
                    <p>Coming soon.</p>
                    {/* <textarea id="boostedKeywords" value={boostedKeywords} onChange={e => setBoostedKeywords(e.target.value)} name="boostedKeywords" rows="3" className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"></textarea> */}
                  </div>
                  <p className="mt-3 text-sm leading-6 text-gray-600">
                    Write a boosted for your agent. Refer to <b>this</b> to
                    learn more.
                  </p>
                </div>
                <div className="w-full">
                  <label
                    htmlFor="filler-words"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                    Filler Words
                  </label>
                  <select
                    value={agentConfig.agent_settings.filler_audio}
                    onChange={(e) =>
                      setAgentConfig({
                        ...agentConfig,
                        agent_settings: {
                          ...agentConfig.agent_settings,
                          filler_audio: e.target.value as FillerAudios
                        }
                      })
                    }
                    name="filler-words"
                    autoComplete="model-id"
                    id="filler-words"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                    {fillerAudios.map((language) => (
                      <option value={language} key={language}>
                        {language}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </form>
          </div>

          {/* Action Settings */}
          <div className="p-4 mb-4 bg-white border border-gray-200 rounded-lg shadow-sm 2xl:col-span-2 dark:border-gray-700 sm:p-6 dark:bg-gray-800">
            <h2 className="text-base font-semibold leading-7 text-gray-900">
              Actions
            </h2>
            <p className="mt-1 text-sm leading-6 text-gray-600">
              Manage Actions here.
            </p>

            <label htmlFor="action-order-staus-bc">
              Order Status (BigCommerce)
            </label>
            <Switch
              checked={agentConfig.enable_action}
              onChange={(e) =>
                setAgentConfig({
                  ...agentConfig,
                  enable_action: !agentConfig.enable_action
                })
              }
              name="action-order-staus-bc"
              color="primary"
            />
          </div>
        </div>
      </div>
    </>
  );
}
