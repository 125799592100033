import { v4 as uuidv4 } from 'uuid';
import { createFileRoute } from '@tanstack/react-router';
import React from "react";

// export function ChatbotEmbed() {
//   return (
//     <>
//     <iframe src={"http://localhost:5173/chatbot?agentId=8e250200-46cc-4650-bdbd-4410ccdeafac&customerId=xyz-123"} />
//     </>
//   );
// }

export const Route = createFileRoute('/chat_embed')({
  component: PreviewIframe,
});

type Props = {
  customerId: string;
};

function PreviewIframe ({ customerId }: Props) {
  const agentId = '8e250200-46cc-4650-bdbd-4410ccdeafac'
  const [hostUrl] = React.useState<string>(
    () =>
      import.meta.env.VITE_HOST_URL ||
      window.location.protocol + "//" + window.location.host
  );

  return (
    <>
      <div>
        <iframe
          src={`${hostUrl}/chatbot/?agentId=${agentId}&customerId=${uuidv4()}`}
          className="w-200 bg-white"
          height={585}
          title="Adapt"
        />
      </div>
    </>
  );
};

export default PreviewIframe