import axios from 'axios';
import { AxiosRequestConfig } from 'axios';
import { getAuth } from 'firebase/auth';
import {
  AgentConfig,
  CallAnalytics,
  CallRecordSchema,
  ChatMessage,
  ChatRecord,
  ChatRecordSchema,
  ChatRegisterResponse,
  ChatRegisterResponseSchema
} from './types/api';
import { GetCallAnalyticsResponseSchema } from './types/api';
import { CallRecord } from './types/api';

const BASE_URL = `${import.meta.env.VITE_API_URL}`;

const AuthAxios = async (req: AxiosRequestConfig) => {
  const token = (await getAuth().currentUser?.getIdToken()) || '';
  return axios({
    ...req,
    headers: {
      ...req.headers,
      authorization: `Bearer ${token}`
    }
  });
};

const getCsatScore = async (): Promise<{ score: number }> => {
  const path = BASE_URL.concat('analytics');
  try {
    const response = await AuthAxios({
      method: 'get',
      url: path
    });
    if (response.data.success) {
      return response.data.payload;
    } else {
      throw new Error('Failed to fetch CSAT score');
    }
  } catch (error) {
    // Handle or throw the error appropriately
    console.error('Error fetching CSAT score:', error);
    throw error;
  }
};
import { z } from 'zod';
import { error } from 'console';

const getCallAnalytics = async (): Promise<{ analytics: CallAnalytics }> => {
  const path = BASE_URL.concat('analytics');
  try {
    const response = await AuthAxios({
      method: 'get',
      url: path
    });
    GetCallAnalyticsResponseSchema.parse(response.data);

    if (response.data.success) {
      return response.data.payload;
    } else {
      throw new Error('Failed to fetch Analytics');
    }
  } catch (error) {
    // Handle or throw the error appropriately
    console.error('Error fetching Analytics:', error);
    throw error;
  }
};

// const registerAgent = async (queryKey): Promise<{ id: string }> => {
//     const [config] = queryKey;
//     const path = BASE_URL.concat('agents')
//     try {

//         const response = await AuthAxios({
//             method: 'post',
//             url: path,
//             data: config
//         })
//         if (response.data.success) {
//             return response.data.payload;
//         } else {
//             throw new Error('Failed to register agent.');
//         }
//     } catch (error) {
//         // Handle or throw the error appropriately
//         console.error('Failed to register agent:', error);
//         throw error;
//     }
// }

const getAllAgents = async (): Promise<AgentConfig[]> => {
  const path = BASE_URL.concat('agents');
  try {
    const response = await AuthAxios({
      method: 'get',
      url: path
    });
    if (response.status == 200) {
      return response.data;
    } else {
      throw new Error('Failed to fetch agents');
    }
  } catch (error) {
    // Handle or throw the error appropriately
    console.error('Error fetching agents:', error);
    throw error;
  }
};

const createDefaultAgent = async (): Promise<{ id: string }> => {
  const path = BASE_URL.concat('agents');
  try {
    const response = await AuthAxios({
      method: 'post',
      url: path,
      data: {}
    });
    if (response.status == 200) {
      return response.data;
    } else {
      throw new Error('Failed to register agent.');
    }
  } catch (error) {
    // Handle or throw the error appropriately
    console.error('Failed to register agent:', error);
    throw error;
  }
};

const deleteAgent = async (agent_id: string): Promise<{}> => {
  const path = BASE_URL.concat('agents/', agent_id);
  try {
    const response = await AuthAxios({
      method: 'delete',
      url: path
    });
    if (response.status == 200) {
      return response.data;
    } else {
      throw new Error('Failed to register agent.');
    }
  } catch (error) {
    // Handle or throw the error appropriately
    console.error('Failed to register agent:', error);
    throw error;
  }
};

const patchAgent = async (agent: AgentConfig): Promise<{ id: string }> => {
  const path = BASE_URL.concat('agents/', agent.agent_id);
  try {
    const response = await AuthAxios({
      method: 'patch',
      url: path,
      data: agent
    });
    if (response.status == 200) {
      return response.data;
    } else {
      throw new Error('Failed to register agent.');
    }
  } catch (error) {
    // Handle or throw the error appropriately
    console.error('Failed to register agent:', error);
    throw error;
  }
};

const getAllVoices = async (): Promise<string[]> => {
  const path = BASE_URL.concat('agents/voices');
  try {
    const response = await AuthAxios({
      method: 'get',
      url: path
    });
    if (response.status == 200) {
      return response.data;
    } else {
      throw new Error('Failed to fetch agents');
    }
  } catch (error) {
    // Handle or throw the error appropriately
    console.error('Error fetching agents:', error);
    throw error;
  }
};

const getAllModels = async (): Promise<string[]> => {
  const path = BASE_URL.concat('agents/models');
  try {
    const response = await AuthAxios({
      method: 'get',
      url: path
    });
    if (response.status == 200) {
      return response.data;
    } else {
      throw new Error('Failed to fetch agents');
    }
  } catch (error) {
    // Handle or throw the error appropriately
    console.error('Error fetching agents:', error);
    throw error;
  }
};

const getAllLanguages = async (): Promise<string[]> => {
  const path = BASE_URL.concat('agents/languages');
  try {
    const response = await AuthAxios({
      method: 'get',
      url: path
    });
    if (response.status == 200) {
      return response.data;
    } else {
      throw new Error('Failed to fetch agents');
    }
  } catch (error) {
    // Handle or throw the error appropriately
    console.error('Error fetching agents:', error);
    throw error;
  }
};

const getAllFillerAudios = async (): Promise<string[]> => {
  const path = BASE_URL.concat('agents/filler_audios');
  try {
    const response = await AuthAxios({
      method: 'get',
      url: path
    });
    if (response.status == 200) {
      return response.data;
    } else {
      throw new Error('Failed to fetch agents');
    }
  } catch (error) {
    // Handle or throw the error appropriately
    console.error('Error fetching agents:', error);
    throw error;
  }
};

const getAllKeys = async (): Promise<string[]> => {
  const path = BASE_URL.concat('auth/');
  try {
    const response = await AuthAxios({
      method: 'get',
      url: path
    });
    if (response.status == 200) {
      return response.data;
    } else {
      throw new Error('Failed to fetch agents');
    }
  } catch (error) {
    // Handle or throw the error appropriately
    console.error('Error fetching agents:', error);
    throw error;
  }
};

const generateKey = async (): Promise<{ msg: string }> => {
  const path = BASE_URL.concat('auth/');
  try {
    const response = await AuthAxios({
      method: 'post',
      url: path
    });
    if (response.status == 200) {
      return response.data;
    } else {
      throw new Error('Failed to register agent.');
    }
  } catch (error) {
    // Handle or throw the error appropriately
    console.error('Failed to register agent:', error);
    throw error;
  }
};

const deleteKey = async (key: string): Promise<{ msg: string }> => {
  const path = BASE_URL.concat('auth/', key);
  try {
    const response = await AuthAxios({
      method: 'delete',
      url: path
    });
    if (response.status == 200) {
      return response.data;
    } else {
      throw new Error('Failed to register agent.');
    }
  } catch (error) {
    // Handle or throw the error appropriately
    console.error('Failed to register agent:', error);
    throw error;
  }
};

const getAllPhones = async (): Promise<[]> => {
  const path = BASE_URL.concat('inbound');
  try {
    const response = await AuthAxios({
      method: 'get',
      url: path
    });
    if (response.status == 200) {
      return response.data;
    } else {
      throw new Error('Failed to fetch agents');
    }
  } catch (error) {
    // Handle or throw the error appropriately
    console.error('Error fetching agents:', error);
    throw error;
  }
};

const sleep = (milliseconds: number) =>
  new Promise((resolve) => setTimeout(resolve, milliseconds));

const addPhone = async (): Promise<{ message: string }> => {
  const path = BASE_URL.concat('inbound/purchase');
  if (import.meta.env.VITE_RUNTIME_ENV === 'local') {
    console.log('Running local');
    await sleep(4000);
    return Promise.resolve({ message: '+2910491201' });
  } else {
    try {
      const response = await AuthAxios({
        method: 'post',
        url: path,
        data: {}
      });
      if (response.status == 200) {
        return response.data;
      } else {
        throw new Error('Failed to register agent.');
      }
    } catch (error) {
      // Handle or throw the error appropriately
      console.error('Failed to register agent:', error);
      throw error;
    }
  }
};

const updateInboundPhone = async (phone: any): Promise<{ message: string }> => {
  const path = BASE_URL.concat('inbound');
  try {
    const response = await AuthAxios({
      method: 'patch',
      url: path,
      data: phone
    });
    if (response.status == 200) {
      return response.data;
    } else {
      throw new Error('Failed to register agent.');
    }
  } catch (error) {
    // Handle or throw the error appropriately
    console.error('Failed to register agent:', error);
    throw error;
  }
};

const getAllCalls = async (): Promise<CallRecord[]> => {
  const path = BASE_URL.concat('calls');
  try {
    const response = await AuthAxios({
      method: 'get',
      url: path
    });
    if (response.status == 200) {
      return response.data;
    } else {
      throw new Error('Failed to fetch calls');
    }
  } catch (error) {
    // Handle or throw the error appropriately
    console.error('Error fetching agents:', error);
    throw error;
  }
};

const getCall = async (callId: string): Promise<CallRecord> => {
  const path = BASE_URL.concat(`calls/${callId}`);
  console.log('Executing getCall with callId' + callId);
  try {
    const response = await AuthAxios({
      method: 'get',
      url: path
    });
    if (response.status == 200) {
      return CallRecordSchema.parse(response.data);
    } else {
      throw new Error('Failed to fetch calls');
    }
  } catch (error) {
    // Handle or throw the error appropriately
    console.error('Error fetching agents:', error);
    throw error;
  }
};

const getPhoneTransferTypes = async (): Promise<string[]> => {
  const path = BASE_URL.concat('calls/transfer_types');
  try {
    const response = await AuthAxios({
      method: 'get',
      url: path
    });
    if (response.status == 200) {
      return response.data;
    } else {
      throw new Error('Failed to fetch calls');
    }
  } catch (error) {
    // Handle or throw the error appropriately
    console.error('Error fetching agents:', error);
    throw error;
  }
};

const getLanguageAnalytics = async (): Promise<any> => {
  const series = [
    {
      name: 'English',
      data: [0, 0, 0, 0, 0, 0, 100],
      color: '#f5bb0c'
    },
    {
      name: 'Spanish',
      data: [1, 24, 39, 18, 2, 8, 82],
      color: '#FDBA8C'
    }
  ];
  const xlabels = [
    '12 Mar',
    '13 Mar',
    '14 Mar',
    '15 Mar',
    '16 Mar',
    '17 Mar',
    '18 Mar'
  ];
  return {
    series,
    xlabels
  };
};

const getAnalyticsData = async (): Promise<any> => {
  return {
    inboundCalls: {
      value: 1,
      dayChange: '100%'
    },
    handoffRate: {
      value: 1,
      dayChange: '100%'
    },
    activeCalls: {
      value: 0,
      dayChange: '0%'
    },
    totalActions: {
      value: 1,
      dayChange: '100%'
    },
    averageDuration: {
      value: '52s',
      dayChange: '100%'
    },
    aiSpeakPercentage: {
      value: 62,
      dayChange: '100%'
    },
    resolvePercentage: {
      value: 100,
      dayChange: '100%'
    },
    intentsData: [
      { name: 'Freeze Card', width: '100%' },
      { name: 'Balance', width: '0%' },
      { name: 'PIN Change', width: '0%' },
      { name: 'Address Change', width: '0%' }
    ]
  };
};

const getCsatAnalytics = async (): Promise<any> => {
  const series = [
    {
      name: 'CSAT',
      data: [0, 0, 0, 0, 0, 0, 93],
      color: '#1A56DB'
    }
  ];
  const xlabels = [
    '12 Mar',
    '13 Mar',
    '14 Mar',
    '15 Mar',
    '16 Mar',
    '17 Mar',
    '18 Mar'
  ];
  return {
    series,
    xlabels
  };
};

const registerChat = async ({
  agent_id,
  customer_id
}: {
  agent_id: string;
  customer_id: string;
}): Promise<ChatRegisterResponse> => {
  const path = BASE_URL.concat('chats/register');
  try {
    const response = await axios({
      method: 'post',
      url: path,
      data: { agent_id, customer_id }
    });
    if (response.status == 200) {
      return ChatRegisterResponseSchema.parse(response.data);
    } else {
      throw new Error('Failed to register agent.');
    }
  } catch (error) {
    // Handle or throw the error appropriately
    console.error('Failed to register agent:', error);
    throw error;
  }
};

const postChatResponse = async ({
  chat_id,
  messages
}: {
  chat_id: string;
  messages: Array<ChatMessage>;
}): Promise<Array<ChatMessage>> => {
  const path = BASE_URL.concat(`chats/${chat_id}`);
  try {
    const response = await axios({
      method: 'post',
      url: path,
      data: { transcript: messages }
    });
    if (response.status == 200) {
      return response.data;
    } else {
      throw new Error('Failed to register agent.');
    }
  } catch (error) {
    // Handle or throw the error appropriately
    console.error('Failed to register agent:', error);
    throw error;
  }
};

const getAllChats = async (): Promise<Array<ChatRecord>> => {
  const path = BASE_URL.concat(`chats/`);
  try {
    const response = await AuthAxios({
      method: 'get',
      url: path,
    });
    if (response.status == 200) {
      return response.data;
    } else {
      throw new Error('Failed to register agent.');
    }
  } catch (error) {
    // Handle or throw the error appropriately
    console.error('Failed to register agent:', error);
    throw error;
  }
};

const getChat = async (chatId: string): Promise<ChatRecord> => {
  const path = BASE_URL.concat(`chats/${chatId}`);
  console.log('Executing getChat with chatId' + chatId);
  try {
    const response = await AuthAxios({
      method: 'get',
      url: path
    });
    if (response.status == 200) {
      return response.data
    } else {
      throw new Error('Failed to fetch chat');
    }
  } catch (error) {
    console.error('Error fetching chat:', error);
    throw error;
  }
};

const getRecording = async (
  callId: string
): Promise<{ audio: Blob | null }> => {
  const path = BASE_URL.concat('calls/recording/', callId);
  try {
    const response = await AuthAxios({
      method: 'get',
      url: path,
      responseType: 'blob'
    });
    if (response.status == 200) {
      return response.data;
    } else {
      return { audio: null };
    }
  } catch (error) {
    // Handle or throw the error appropriately
    console.error('Failed to get recording:', error);
    return { audio: null };
  }
};

export {
  addPhone,
  createDefaultAgent,
  deleteAgent,
  deleteKey,
  generateKey,
  getAllAgents,
  getAllCalls,
  getAllChats,
  getAllFillerAudios,
  getAllKeys,
  getAllLanguages,
  getAllModels,
  getAllPhones,
  getAllVoices,
  getAnalyticsData,
  getCall,
  getCallAnalytics,
  getChat,
  getCsatAnalytics,
  getCsatScore,
  getLanguageAnalytics,
  getPhoneTransferTypes,
  getRecording,
  patchAgent,
  postChatResponse,
  registerChat,
  updateInboundPhone,
};
