// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBgca7kDnwIsTD6NAYcGn7sL_UktyCEmCo",
  authDomain: "get-adapt-ai.firebaseapp.com",
  databaseURL: "https://get-adapt-ai-default-rtdb.firebaseio.com",
  projectId: "get-adapt-ai",
  storageBucket: "get-adapt-ai.appspot.com",
  messagingSenderId: "933048362880",
  appId: "1:933048362880:web:bb1b6c8d413ffeb6d80365",
  measurementId: "G-J0GKSWPR0Q"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

function getCurrentUserIdToken() {
  const auth = getAuth();
  const user = auth.currentUser;

  if (user) {
    // User is signed in, get the ID token
    return user.getIdToken().then((idToken) => {
      return idToken;
    }).catch((error) => {
      // Handle any errors while getting the ID token
      throw error;
    });
  } else {
    // No user is signed in
    return "";
  }
}

export {
    app as fbApp,
    analytics as fbAnalytics,
    getCurrentUserIdToken
}