import { useState, useEffect } from 'react';
import Chart from 'react-apexcharts';

type AreaChartProps = {
  series: Array<any>;
  xlabels: Array<string>;
};

const AreaChart = ({ series, xlabels }: AreaChartProps) => {
  const [chartOptions, setChartOptions] = useState<ApexCharts.ApexOptions>(
    getMainChartOptions(false, series, xlabels)
  );

  useEffect(() => {
    // Function to update chart options based on the dark mode
    const handleDarkMode = () => {
      const isDarkMode = document.documentElement.classList.contains('dark');
      setChartOptions(getMainChartOptions(isDarkMode, series, xlabels));
    };
    setChartOptions(getMainChartOptions(false, series, xlabels));

    // Event listener for dark mode toggle
    document.addEventListener('dark-mode', handleDarkMode);

    // Cleanup event listener
    return () => {
      document.removeEventListener('dark-mode', handleDarkMode);
    };
  }, []);

  return (
    <Chart
      options={chartOptions}
      series={chartOptions.series}
      type="area"
      height={420}
    />
  );
};

const getMainChartOptions = (
  isDarkMode: any,
  series: Array<any>,
  xlabels: Array<string>
) => {
  const mainChartColors = isDarkMode
    ? {
        borderColor: '#374151',
        labelColor: '#9CA3AF',
        opacityFrom: 0,
        opacityTo: 0.15
      }
    : {
        borderColor: '#F3F4F6',
        labelColor: '#6B7280',
        opacityFrom: 0.45,
        opacityTo: 0
      };

  return {
    chart: {
      height: 420,
      type: 'area' as const,
      fontFamily: 'Inter, sans-serif',
      foreColor: mainChartColors.labelColor,
      toolbar: {
        show: false
      }
    },
    fill: {
      type: 'gradient',
      gradient: {
        enabled: true,
        opacityFrom: mainChartColors.opacityFrom,
        opacityTo: mainChartColors.opacityTo
      }
    },
    dataLabels: {
      enabled: false
    },
    tooltip: {
      style: {
        fontSize: '14px',
        fontFamily: 'Inter, sans-serif'
      }
    },
    grid: {
      show: true,
      borderColor: mainChartColors.borderColor,
      strokeDashArray: 1,
      padding: {
        left: 35,
        bottom: 15
      }
    },
    series: series,
    markers: {
      size: 5,
      strokeColors: '#ffffff',
      hover: {
        size: undefined,
        sizeOffset: 3
      }
    },
    xaxis: {
      categories: xlabels,
      labels: {
        style: {
          colors: [mainChartColors.labelColor],
          fontSize: '14px',
          fontWeight: 500
        }
      },
      axisBorder: {
        color: mainChartColors.borderColor
      },
      axisTicks: {
        color: mainChartColors.borderColor
      },
      crosshairs: {
        show: true,
        position: 'back',
        stroke: {
          color: mainChartColors.borderColor,
          width: 1,
          dashArray: 10
        }
      }
    },
    yaxis: {
      labels: {
        style: {
          colors: [mainChartColors.labelColor],
          fontSize: '14px',
          fontWeight: 500
        },
        formatter: function (value: any) {
          return value + '%';
        }
      }
    },
    legend: {
      fontSize: '14px',
      fontWeight: 500,
      fontFamily: 'Inter, sans-serif',
      labels: {
        colors: [mainChartColors.labelColor]
      },
      itemMargin: {
        horizontal: 10
      }
    },
    responsive: [
      {
        breakpoint: 1024,
        options: {
          xaxis: {
            labels: {
              show: false
            }
          }
        }
      }
    ]
  };
};

export default AreaChart