import { createFileRoute, redirect } from '@tanstack/react-router';
import { getAuth } from 'firebase/auth';
import * as queries from '../queries';
import { CallRecord } from '../types/api';

export const Route = createFileRoute('/calls/$callId')({
  beforeLoad: async ({ location }) => {
    if (!getAuth().currentUser) {
      throw redirect({
        to: '/login'
      });
    }
  },
  loader: ({ params: { callId } }) => queries.getCall(callId),
  component: CallIdComponent
});

export function CallIdComponent() {
  const callRecord = Route.useLoaderData<CallRecord>();
  return (
    <div className="flex items-center mt-6">
      <div className="px-4 pt-6" />
      <div>
        <div>
        <button onClick={() => window.history.back()} className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
          Go Back
        </button>

        </div>
        <div className="p-4 mb-4 bg-white border border-gray-200 rounded-lg shadow-sm 2xl:col-span-2 dark:border-gray-700 sm:p-6 dark:bg-gray-800">
          <h2 className="mb-4 text-xl font-bold text-gray-900 dark:text-white">
          </h2>
            {callRecord.transcript?.map((dialog, index) => {
              return (<div key={index}><b>{dialog.sender}: </b>{dialog.text}</div>)
            })}
        </div>
      </div>
    </div>
  );
}
