import React from 'react';
import { useAuth } from '../auth';
import { getAuth } from 'firebase/auth';
import { fbApp } from '../firebase';
import { useNavigate } from '@tanstack/react-router';

const SidebarToggle = () => (
  <button
    aria-controls="logo-sidebar"
    className="inline-flex items-center p-2 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
    data-drawer-target="logo-sidebar"
    data-drawer-toggle="logo-sidebar"
    type="button">
    <span className="sr-only">Open sidebar</span>
    <svg
      aria-hidden="true"
      className="w-6 h-6"
      fill="currentColor"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg">
      <path
        clipRule="evenodd"
        d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"
        fillRule="evenodd"
      />
    </svg>
  </button>
);

const UserDropdownMenu = () => (
  <div className="flex items-center ms-3">
    <div>
      <button
        aria-expanded="false"
        className="flex text-sm bg-gray-800 rounded-full focus:ring-4 focus:ring-gray-300 dark:focus:ring-gray-600"
        data-dropdown-toggle="dropdown-user"
        type="button">
        <span className="sr-only">Open user menu</span>
        <img
          alt="user photo"
          className="w-8 h-8 rounded-full"
          src="https://flowbite.com/docs/images/people/profile-picture-5.jpg"
        />
      </button>
    </div>
    <div
      className="z-50 hidden my-4 text-base list-none bg-white divide-y divide-gray-100 rounded shadow dark:bg-gray-700 dark:divide-gray-600"
      id="dropdown-user">
      <div className="px-4 py-3" role="none">
        <p className="text-sm text-gray-900 dark:text-white" role="none">
          Neil Sims
        </p>
        <p
          className="text-sm font-medium text-gray-900 truncate dark:text-gray-300"
          role="none">
          neil.sims@flowbite.com
        </p>
      </div>
      <ul className="py-1" role="none">
        <li>
          <a
            className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white"
            href="#"
            role="menuitem">
            Dashboard
          </a>
        </li>
        <li>
          <a
            className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white"
            href="#"
            role="menuitem">
            Settings
          </a>
        </li>
        <li>
          <a
            className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white"
            href="#"
            role="menuitem">
            Earnings
          </a>
        </li>
        <li>
          <a
            className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white"
            href="#"
            role="menuitem">
            Sign out
          </a>
        </li>
      </ul>
    </div>
  </div>
);

const NavBarFL = () => {
  const auth = useAuth();
  const navigate = useNavigate();

  return (
    <div className="px-3 py-3 lg:px-5 lg:pl-3">
      <div className="flex items-center justify-between">
        <div className="flex items-center justify-start rtl:justify-end">
          <SidebarToggle />
          <a className="flex ms-2 md:me-24" href="https://getadapt.ai">
            <img
              alt="Adapt Logo"
              className="h-8 me-3"
              src="/adapt-logo-icon.png"
            />
            <span className="self-center text-xl font-semibold sm:text-2xl whitespace-nowrap dark:text-white">
              Adapt
            </span>
          </a>
        </div>
        {/* <div className="flex items-center">
          <UserDropdownMenu />
        </div> */}
        <div className="flex md:order-2 space-x-3 md:space-x-0 gap-2 rtl:space-x-reverse">
          {!auth.isAuthenticated && (
            <>
              <button
                onClick={() => {
                  window.location.href = '/login';
                }}
                className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                type="button">
                Login
              </button>
              <button
                onClick={() => {
                  window.location.href = '/signup';
                }}
                className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                type="button">
                Sign Up
              </button>
            </>
          )}
          {auth.isAuthenticated && (
            <>
              <button
                onClick={async () => {
                  const auth = getAuth(fbApp);
                  await auth.signOut();
                  navigate({ to: '/login' });
                }}
                className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                type="button">
                Logout
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
export default NavBarFL;
