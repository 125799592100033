import { AgentConfig } from "../types/api";
import { useState } from "react";

interface AgentDropdownProps {
  agents: AgentConfig[];
  onSelectAgent: (agent: AgentConfig | null) => void;
}
const AgentDropdown = ({ agents, onSelectAgent }: AgentDropdownProps) => {
  const [selectedAgent, setSelectedAgent] = useState('');

  const handleSelectAgent = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const agentId = event.target.value;
    setSelectedAgent(agentId);
    if(agentId === ""){
      onSelectAgent(null);
      return;
    } 
    const agent = agents.find((agent) => agent.agent_id === agentId);
    if (agent) {
      onSelectAgent(agent);
    }
  };

  return (
    <div className="w-full max-w-xs mx-auto">
      <label
        htmlFor="agent-dropdown"
        className="block text-sm font-medium text-gray-700">
        Choose an agent:
      </label>
      <select
        id="agent-dropdown"
        name="agent-dropdown"
        value={selectedAgent}
        onChange={handleSelectAgent}
        className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md shadow-sm">
        <option value="">Select an agent...</option>
        {agents.map((agent) => (
          <option key={agent.agent_id} value={agent.agent_id}>
            {agent.name}
          </option>
        ))}
      </select>
    </div>
  );
};

export default AgentDropdown