export const CallTableSearchBox = () => {
  return (
    <form action="#" className="sm:pr-3" method="GET">
      <label className="sr-only" htmlFor="call-search">
        Search
      </label>
      <div className="relative w-48 mt-1 sm:w-64 xl:w-96">
        <input
          className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
          id="call-search"
          name="email"
          placeholder="Search for calls"
          type="text"
        />
      </div>
    </form>
  );
};
