import z from 'zod';

type DataSource = {
  name: string;
  type: string;
  url: string;
};

export const IntentType = z.enum([
  'order-status',
  'returns',
  'product-inquiry',
  'tax-invoice'
]);
export const IntentSchema = z.object({
  type: IntentType,
  name: z.string(),
  value: z.number()
});

export const CallAnalyticsSchema = z.object({
  intents: z.array(IntentSchema),
  csatScore: z.number()
});
export type CallAnalytics = z.infer<typeof CallAnalyticsSchema>;

export const GetCallAnalyticsResponseSchema = z.object({
  payload: CallAnalyticsSchema,
  success: z.boolean()
});

export const TranscriptSchema = z.object({
  conversation_id: z.string(),
  sender: z.string(),
  text: z.string(),
  timestamp: z.number(),
  type: z.string()
})

export const CallRecordSchema = z.object({
  call_id: z.string(),
  from_phone: z.string().optional(),
  to_phone: z.string().optional(),
  duration: z.number().optional(),
  twilio_sid: z.string().optional(),
  type: z.string().optional(),
  user_id: z.string(),
  transcript: z.array(TranscriptSchema).optional(),
  recording: z.string().optional()
});

export type CallRecord = z.infer<typeof CallRecordSchema>;

export const ActionSchema = z.object({
  name: z.string(),
  code: z.string(),
  enabled: z.boolean()
});
export type Action = z.infer<typeof ActionSchema>;


export const FillerAudiosSchema = z.enum(["Typing Noise", "No Filler Noise"]);

export type FillerAudios = z.infer<typeof FillerAudiosSchema>

export const AgentModelsSchema = z.enum([
  "GPT-4 (slow)",
  "GPT-3.5 (fast)",
  "Clause 3 Opus (slow)",
  "Clause 3 Sonnet (slow)",
  "Clause 2 (slow)",
  "Clause 1 (fast)",
  "Mistral Tiny (fast)",
  "Mistral Small (fast)",
  "Mistral Medium (slow)",
  "Mistral Large (slow)",
  "Groq LLama2-70b",
  "Groq Mixtral-8x7b",
  "Groq Gemma-7b-it",
  "Azure GPT-3.5"
]);

export type AgentModels = z.infer<typeof AgentModelsSchema>;

export const VoiceSettingsSchema = z.object({
  stability: z.number().default(0.5).describe("Voice stability setting."),
  similarity: z.number().default(0.8).describe("Voice similarity setting."),
  speed: z.number().default(1.0).describe("Voice speed setting."),
  voice_id: z.string().default("natasha").describe("Voice ID setting."),
  audio_encoding: z.enum(["LINEAR16"]).default("LINEAR16"),
  sampling_rate: z.number().default(48000),
  should_encode_as_wav: z.boolean().default(true)
});
export type VoiceSettings = z.infer<typeof VoiceSettingsSchema>;

export const AgentSettingsSchema = z.object({
  model_id: AgentModelsSchema.default("GPT-3.5 (fast)" as AgentModels).describe("LLM Model"),
  system_prompt: z.string().optional().describe("System prompt"),
  greeting: z.string().optional().describe("Opening message or greeting.").default("How can I assist you today?"),
  filler_audio: FillerAudiosSchema.default("No Filler Noise" as FillerAudios).describe("Filler Audio type")
});
export type AgentSettings = z.infer<typeof AgentSettingsSchema>;

export const AgentConfigSchema = z.object({
  name: z.string(),
  agent_id: z.string(),
  agent_settings: AgentSettingsSchema,
  voice_settings: VoiceSettingsSchema,
  enable_action: z.boolean(),
  language: z.string()
});
export type AgentConfig = z.infer<typeof AgentConfigSchema>;

export const InboundPhoneNumberSchema = z.object({
  agent_id: z.string(),
  phone_number: z.string(),
  sid: z.string(),
  transfer_list: z.record(z.string()), // Assuming a map with string keys and any type of values
  user_id: z.string(),
});

export type InboundPhoneNumber = z.infer<typeof InboundPhoneNumberSchema>;


export const ChatMessageSchema = z.object({
  sender: z.enum(['bot', 'human']),
  text: z.string(),
  timestamp: z.number(),
});

export type ChatMessage = z.infer<typeof ChatMessageSchema>;

export const ChatRecordSchema = z.object({
  conversation_id: z.string().describe("The unique identifier for the chat."),
  transcript: z.array(ChatMessageSchema).describe("The transcript of the chat as an array of transcript entries."),
  user_id: z.string().optional().describe("The user ID associated with the chat."),
  agent_config: AgentConfigSchema.optional(),
  created_at: z.number(),
  duration: z.number().optional(),
  customer_id: z.string()
});
export type ChatRecord = z.infer<typeof ChatRecordSchema>;

export const ChatRegisterRequestSchema = z.object({
  agent_id: z.string(),
  customer_id: z.string(),
});
export type ChatRegisterRequest = z.infer<typeof ChatRegisterRequestSchema>;

export const ChatPostRequestSchema = z.object({
  transcript: z.array(ChatMessageSchema).default([]),
});
export type ChatPostRequest = z.infer<typeof ChatPostRequestSchema>;

export const ChatRegisterResponseSchema = z.object({
  conversation_id: z.string(),
  greeting: z.array(ChatMessageSchema),
});
export type ChatRegisterResponse = z.infer<typeof ChatRegisterResponseSchema>;

export const ChatPostResponseSchema = z.object({
  transcript: z.array(ChatMessageSchema),
});
export type ChatPostResponse = z.infer<typeof ChatPostResponseSchema>;
