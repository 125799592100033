import { createFileRoute, redirect } from '@tanstack/react-router';
import { getAuth } from 'firebase/auth';
import { useState, useEffect } from 'react';
import Chart from 'react-apexcharts';
import { useQuery } from '@tanstack/react-query';
import * as queries from '../queries';
// import CsatAreaChart from '../components/dashboard/csat_area_chart';
import AreaChart from '../components/dashboard/AreaChart';
import DashboardChartCard from '../components/dashboard/DashboardChartCard';
import DashboardCard from '../components/dashboard/DashboardCard';

export const Route = createFileRoute('/dashboard')({
  beforeLoad: async ({ location }) => {
    if (!getAuth().currentUser) {
      throw redirect({
        to: '/login'
      });
    }
  },
  component: Dashboard
});

export default function Dashboard() {
  const [disable, setDisable] = useState<boolean>(true);

  return (
    <div id="dashboard" className="relative h-full">
      <div
        className={`px-4 pt-6 ${disable ? 'pointer-events-none blur-sm' : ''}`}>
        <div className="grid w-full grid-cols-3 gap-4 mt-4 xl:grid-cols-3 2xl:grid-cols-3">
          <InboundCallsCardData />
          <HandoffRateCardData />
          <ActiveNowCardData />
        </div>
        <div className="grid gap-4 xl:grid-cols-2 2xl:grid-cols-2 mt-4">
          <div className="p-4 bg-white border border-gray-200 rounded-lg shadow-sm dark:border-gray-700 sm:p-6 dark:bg-gray-800">
            <CsatAreaChart />
          </div>
          <div className="p-4 bg-white border border-gray-200 rounded-lg shadow-sm dark:border-gray-700 sm:p-6 dark:bg-gray-800">
            <LanguageAreaChart />
          </div>
        </div>
        <div className="grid w-full grid-cols-2 gap-4 mt-4">
          <div className="grid w-full grid-cols-2 gap-4 xl:grid-cols-2 2xl:grid-cols-2">
            <ActionsCardData />
            <AverageDurationCardData />
            <AISpeakCardData />
            <ResolveCardData />
          </div>
          <IntentsCard />
        </div>
      </div>
      {disable && (
        <div
          id="preview-dashboard"
          className="absolute inset-0 w-full h-full bg-black bg-opacity-50 z-50 flex justify-center items-center">
          <div className="text-center text-gray-100 text-4xl">Coming Soon</div>
        </div>
      )}
    </div>
  );
}
const InboundCallsCardData = () => {
  const { data: analyticsData, isSuccess } = useQuery({
    queryKey: ['getAnalyticsData'],
    queryFn: queries.getAnalyticsData
  });

  return (
    <>
      {isSuccess && (
        <DashboardCard
          title="Inbound Calls"
          value={analyticsData.inboundCalls.value}
          change={analyticsData.inboundCalls.dayChange}
          changeDescription="Since yesterday"
        />
      )}
    </>
  );
};

const HandoffRateCardData = () => {
  const { data: analyticsData, isSuccess } = useQuery({
    queryKey: ['getAnalyticsData'],
    queryFn: queries.getAnalyticsData
  });

  return (
    <>
      {isSuccess && (
        <DashboardCard
          title="Handoff Rate"
          value={analyticsData.handoffRate.value}
          change={analyticsData.handoffRate.dayChange}
          changeDescription="Since yesterday"
        />
      )}
    </>
  );
};

const ActiveNowCardData = () => {
  const { data: analyticsData, isSuccess } = useQuery({
    queryKey: ['getAnalyticsData'],
    queryFn: queries.getAnalyticsData
  });

  return (
    <>
      {isSuccess && (
        <DashboardCard
          title="Active Calls"
          value={analyticsData.activeCalls.value}
          change={analyticsData.activeCalls.dayChange}
          changeDescription="Since yesterday"
        />
      )}
    </>
  );
};

const ActionsCardData = () => {
  const { data: analyticsData, isSuccess } = useQuery({
    queryKey: ['getAnalyticsData'],
    queryFn: queries.getAnalyticsData
  });

  return (
    <>
      {isSuccess && (
        <DashboardCard
          title="Total Actions"
          value={analyticsData.totalActions.value}
          change={analyticsData.totalActions.dayChange}
          changeDescription="Since yesterday"
        />
      )}
    </>
  );
};

const AverageDurationCardData = () => {
  const { data: analyticsData, isSuccess } = useQuery({
    queryKey: ['getAnalyticsData'],
    queryFn: queries.getAnalyticsData
  });

  return (
    <>
      {isSuccess && (
        <DashboardCard
          title="Average Duration"
          value={analyticsData.averageDuration.value}
          change={analyticsData.averageDuration.dayChange}
          changeDescription="Since yesterday"
        />
      )}
    </>
  );
};

const AISpeakCardData = () => {
  const { data: analyticsData, isSuccess } = useQuery({
    queryKey: ['getAnalyticsData'],
    queryFn: queries.getAnalyticsData
  });

  return (
    <>
      {isSuccess && (
        <DashboardCard
          title="AI Speak Percentage"
          value={analyticsData.aiSpeakPercentage.value}
          change={analyticsData.aiSpeakPercentage.dayChange}
          changeDescription="Since yesterday"
        />
      )}
    </>
  );
};

const ResolveCardData = () => {
  const { data: analyticsData, isSuccess } = useQuery({
    queryKey: ['getAnalyticsData'],
    queryFn: queries.getAnalyticsData
  });

  return (
    <>
      {isSuccess && (
        <DashboardCard
          title="Resolve Percentage"
          value={analyticsData.resolvePercentage.value}
          change={analyticsData.resolvePercentage.dayChange}
          changeDescription="Since yesterday"
        />
      )}
    </>
  );
};
const LanguageAreaChart = () => {
  const { data: languagesChartData, isSuccess } = useQuery({
    queryKey: ['getLanguageAnalytics'],
    queryFn: queries.getLanguageAnalytics
  });

  return (
    <>
      {isSuccess && (
        <DashboardChartCard
          aggPercentage="100%"
          title="Languages"
          percentageChange="0%"
          ChartComponent={() => (
            <AreaChart
              series={languagesChartData.series}
              xlabels={languagesChartData.xlabels}
            />
          )}
        />
      )}
    </>
  );
};
const CsatAreaChart = () => {
  const { data: csatChartData, isSuccess } = useQuery({
    queryKey: ['getCsatAnalytics'],
    queryFn: queries.getCsatAnalytics
  });
  return (
    <>
      {isSuccess && (
        <DashboardChartCard
          aggPercentage="100%"
          title="Languages"
          percentageChange="0%"
          ChartComponent={() => (
            <AreaChart
              series={csatChartData.series}
              xlabels={csatChartData.xlabels}
            />
          )}
        />
      )}
    </>
  );
};

const IntentsCard = () => {
  const { data: analyticsData, isSuccess } = useQuery({
    queryKey: ['getAnalyticsData'],
    queryFn: queries.getAnalyticsData
  });

  return (
    <>
      {isSuccess && (
        <div className="p-4 bg-white border border-gray-200 rounded-lg shadow-sm dark:border-gray-700 sm:p-6 dark:bg-gray-800">
          <div className="w-full">
            <h3 className="mb-2 text-base font-normal text-gray-500 dark:text-gray-400">
              Top Intents
            </h3>
            {analyticsData.intentsData.map((intent: any) => (
              <div className="flex items-center mb-2" key={intent.name}>
                <div className="w-16 text-sm font-medium dark:text-white">
                  {intent.name}
                </div>
                <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
                  <div
                    className="bg-primary-600 h-2.5 rounded-full dark:bg-primary-500"
                    style={{ width: intent.width }}
                  />
                </div>
              </div>
            ))}
          </div>
          <div className="w-full" id="traffic-channels-chart" />
        </div>
      )}
      ;
    </>
  );
};
