import { useConversation } from '../hooks/conversation';
import { useState } from 'react';
import { Navigate, createFileRoute, redirect } from '@tanstack/react-router';
import { useAuth } from '../auth';
import { getAllAgents } from '../queries';
import { useQuery } from '@tanstack/react-query';
import { AgentConfig } from '../types/api';
import { Button } from '@chakra-ui/react';
import { isChrome, isSafari } from 'react-device-detect';
import AudioVisualization from '../components/audio_visualization';
import AgentDropdown from '../components/agent_dropdown';
import { SelfHostedConversationConfig } from '../types';

const WEBSOCKET_URL = `${import.meta.env.VITE_WEBSOCKET_URL}`;

export const Route = createFileRoute('/voice_playground')({
  beforeLoad: async ({ context, location }) => {
    if (!context.auth.isAuthenticated) {
      throw redirect({
        to: '/login'
      });
    }
  },
  component: Blob
});

const GrayBlob = (props: React.HTMLAttributes<HTMLButtonElement>) => {
  const grayBlobStyle = {
    background:
      'radial-gradient(circle at center, rgb(107 114 128) 0, rgb(156 163 175) 25%, white 30%)',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    animationName: 'fadeInOut',
    animationDuration: '2s',
    animationIterationCount: 'infinite',
    animationDirection: 'alternate',
    fontSize: '2em',
    color: 'rgb(226 232 240)'
  };
  return (
    <button {...props} style={{ ...props.style, ...grayBlobStyle }}>
      <p>Click to Talk</p>
      <style>
        {`
        @keyframes fadeInOut {
          from { background-size: 90% 90% }
          to { background-size: 100% 100% }
        `}
      </style>
    </button>
  );
};

const OrangeBlob = (props: React.HTMLAttributes<HTMLButtonElement>) => {
  const blobStyle = {
    background:
      'radial-gradient(circle at center, rgb(249 115 22) 0, rgb(253 186 116) 25%, white 30%)',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    animationName: 'fadeInOut',
    animationDuration: '2s',
    animationIterationCount: 'infinite',
    animationDirection: 'alternate',
    fontSize: '2em',
    fontWeight: '600',
    color: 'rgb(226 232 240)'
  };
  return (
    <button {...props} style={{ ...props.style, ...blobStyle }}>
      Adapt
      <style>
        {`
            @keyframes fadeInOut {
              from { background-size: 90% 90% }
              to { background-size: 100% 100% }
            `}
      </style>
    </button>
  );
};

export default function Blob() {
  const auth = useAuth();
  const [backendUrl, setBackendUrl] = useState('');
  const { status, start, stop, error, analyserNode } = useConversation(backendUrl);
  const { data, isSuccess } = useQuery({
    queryKey: ['getAllAgents'],
    queryFn: getAllAgents
  });
  const [selectedAgent, setSelectedAgent] = useState<AgentConfig | null>(null);

  const handleAgentChange = async (agent: AgentConfig | null) => {
    setSelectedAgent(agent);
    if(agent) setBackendUrl(WEBSOCKET_URL + '?' + 'agent_id=' + agent.agent_id);
  };

  if (!auth.isAuthenticated) {
    return <Navigate to="/login" />;
  } else {
    return (
      <>
        {isSuccess && (
          <main className={`h-full flex flex-col ${!isChrome ? 'pointer-events-none': ''}`}>
            <div className="h-full relative">
              <div className="my-10 h-full flex flex-col grow mx-auto">
                <AgentDropdown
                  agents={data}
                  onSelectAgent={handleAgentChange}
                />
                {
                  status === "error" && (
                    <div>
                    <h2>An error occurred.</h2>
                    </div>
                  )
                }
                {/* <div>
                  <input type="text" className="w-full my-auto border-2 border-black-500 rounded-lg" value={backendUrl}
                    onChange={(e) => setBackendUrl(e.target.value)}
                    placeholder="wss://my-repo.user.repl.co/conversation" />
                </div> */}
                {selectedAgent && selectedAgent.agent_id && (
                  <div className="relative h-full">
                    {analyserNode && (
                      <AudioVisualization analyser={analyserNode} />
                    )}
                    <Button
                      variant="link"
                      disabled={['connecting', 'error'].includes(status)}
                      onClick={status === 'connected' ? stop : start}
                      position={'absolute'}
                      top={'50%'}
                      left={'50%'}
                      transform={'translate(-50%, -50%)'}>
                      <div className="w-64">
                        {status === 'connected' ? 'Stop.' : 'Click to Talk!'}
                      </div>
                    </Button>
                  </div>
                )}
              </div>
              {!isChrome && (
                <div
                  id="preview-playground"
                  className="absolute inset-0 w-full h-full bg-black bg-opacity-50 z-50 flex justify-center items-center">
                  <div className="text-center text-gray-100 text-4xl">This feature is only available on Chrome.</div>
                </div>
              )}
            </div>
          </main>
        )}
      </>
    );
  }
}
