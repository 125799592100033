import {
  createFileRoute,
  redirect,
  useParams
} from '@tanstack/react-router';
import { getAuth } from 'firebase/auth';
import * as React from 'react';
import { useState, useEffect } from 'react';
import * as queries from '../queries';
import { useQuery } from '@tanstack/react-query';
import { Link } from '@tanstack/react-router';
import { TableButton } from '../components/table_button';
import { MessageIcon, PlayIcon } from '../components/icons';
import { CallTableSearchBox } from '../components/calls/CallTableSearchBox';
import { CallTableOptions } from '../components/calls/CallTableOptions';
import { RiFileCopyFill } from 'react-icons/ri';

export const Route = createFileRoute('/calls')({
  beforeLoad: async ({ location }) => {
    if (!getAuth().currentUser) {
      throw redirect({
        to: '/login'
      });
    }
  },
  component: CallTableFL
});

import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable
} from '@tanstack/react-table';
import { CallRecord } from '../types/api';
import { CallTableFooter } from '../components/calls/CallTableFooter';

const columnHelper = createColumnHelper<CallRecord>();

const CallFrom = ({ from, call_id }: { from: string; call_id: string }) => {
  const copyCallId = async () => {
    try {
      await navigator.clipboard.writeText(call_id);
      alert('Call ID copied to clipboard!');
    } catch (err) {
      console.error('Failed to copy: ', err);
    }
  };

  return (
    <>
      {from}{' '}
      <span
        className="inline-flex items-center cursor-pointer"
        onClick={copyCallId}>
        <RiFileCopyFill className="text-gray-300 hover:text-black focus:text-black dark:hover:text-white" />
      </span>
    </>
  );
};

const columns = [
  columnHelper.accessor('from_phone', {
    header: () => <span>Phone Number</span>,
    cell: (info) => (
      <CallFrom
        from={info.getValue() || 'Webcall'}
        call_id={info.row.original.call_id}
      />
    )
  }),
  columnHelper.accessor('duration', {
    cell: (info) => <>{`${info.getValue() || 0}s` || 'NA'}</>,
    header: () => <span>Duration</span>
  }),
  columnHelper.accessor('transcript', {
    header: 'Transcript',
    cell: (info) => (
      <Link
        to={`/calls/$callId`}
        params={{ callId: info.row.original.call_id }}>
        <TableButton text="Transcript" icon={MessageIcon} />
      </Link>
    )
  }),
  columnHelper.accessor('recording', {
    header: 'Recording (Beta)',
    cell: (info) => (
      <a href={info.getValue()}>
        <TableButton text="Recording" icon={PlayIcon} />
      </a>
    )
  })
];

function CallTableFL() {
  const { data: callList, isSuccess: getCallsIsSuccess } = useQuery({
    queryKey: ['getAllCalls'],
    queryFn: queries.getAllCalls
  });

  const params = useParams({ strict: false });
  console.log(params);

  const [data, setData] = useState<CallRecord[]>([]);
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel()
  });
  const [disable, setDisable] = useState<boolean>(false);

  useEffect(() => {
    if (getCallsIsSuccess) {
      setData(callList);
    }
  }, [getCallsIsSuccess]);

  return (
    <div id="calls" className="relative h-full">
      <div className={`${disable ? 'pointer-events-none blur-sm' : ''}`}>
        <div
          className={`p-4 bg-white block sm:flex items-center justify-between border-b border-gray-200 lg:mt-1.5 dark:bg-gray-800 dark:border-gray-700 `}>
          <div className="w-full mb-1">
            <div className="mb-4">
              <h1 className="text-xl font-semibold text-gray-900 sm:text-2xl dark:text-white">
                Calls
              </h1>
            </div>
            <div className="items-center justify-between block sm:flex md:divide-x md:divide-gray-100 dark:divide-gray-700">
              <div className="flex items-center mb-4 sm:mb-0">
                <CallTableSearchBox />
                <CallTableOptions />
              </div>
              <button
                aria-controls="drawer-create-product-default"
                className="text-white bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800"
                data-drawer-placement="right"
                data-drawer-show="drawer-create-product-default"
                data-drawer-target="drawer-create-product-default"
                id="createProductButton"
                type="button">
                Refresh
              </button>
            </div>
          </div>
        </div>
        <div className="flex flex-col">
          <div className="overflow-x-auto">
            <div className="inline-block min-w-full align-middle">
              <div className="overflow-hidden shadow">
                <table className="min-w-full divide-y divide-gray-200 table-fixed dark:divide-gray-600">
                  <thead className="bg-gray-100 dark:bg-gray-700">
                    <tr>
                      <th className="p-4" scope="col">
                        <div className="flex items-center">
                          <input
                            aria-describedby="checkbox-1"
                            className="w-4 h-4 border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 dark:focus:ring-primary-600 dark:ring-offset-gray-800 dark:bg-gray-700 dark:border-gray-600"
                            id="checkbox-all"
                            type="checkbox"
                          />
                          <label className="sr-only" htmlFor="checkbox-all">
                            checkbox
                          </label>
                        </div>
                      </th>
                      {table.getHeaderGroups().map((headerGroup) => (
                        <React.Fragment key={headerGroup.id}>
                          {headerGroup.headers.map((header) => (
                            <th
                              key={header.id}
                              className="p-4 text-xs font-medium text-left text-gray-500 uppercase dark:text-gray-400">
                              {header.isPlaceholder
                                ? null
                                : flexRender(
                                    header.column.columnDef.header,
                                    header.getContext()
                                  )}
                            </th>
                          ))}
                        </React.Fragment>
                      ))}
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200 dark:bg-gray-800 dark:divide-gray-700">
                    {table.getRowModel().rows.map((row) => (
                      <tr
                        key={row.id}
                        className="hover:bg-gray-100 dark:hover:bg-gray-700">
                        <td className="w-4 p-4">
                          <div className="flex items-center">
                            <input
                              aria-describedby="checkbox-1"
                              className="w-4 h-4 border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 dark:focus:ring-primary-600 dark:ring-offset-gray-800 dark:bg-gray-700 dark:border-gray-600"
                              id="checkbox-1"
                              type="checkbox"
                            />
                            <label className="sr-only" htmlFor="checkbox-1">
                              checkbox
                            </label>
                          </div>
                        </td>
                        {row.getVisibleCells().map((cell) => (
                          <td
                            key={cell.id}
                            className="p-4 text-base font-medium text-gray-900 whitespace-nowrap dark:text-white">
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <CallTableFooter table={table} />
        </div>
      </div>
      {disable && (
        <div
          id="preview-calls"
          className="absolute inset-0 w-full h-full bg-black bg-opacity-50 z-50 flex justify-center items-center">
          <div className="text-center text-gray-100 text-4xl">Coming Soon</div>
        </div>
      )}
    </div>
  );
}
