import { Navigate, createFileRoute, redirect } from '@tanstack/react-router'
import { getAuth } from "firebase/auth"
import { useAuth } from '../auth'

export const Route = createFileRoute('/')({
    component: Home
})

export default function Home() {
    const auth = useAuth()
    if (auth.isAuthenticated) {
        return <Navigate to="/voice_playground" />
    }
    else {
        return <Navigate to="/login" />
    }
}
