import { createFileRoute, redirect } from '@tanstack/react-router'
import { getAuth } from "firebase/auth"

export const Route = createFileRoute('/actions')({
  beforeLoad: async ({ location }) => {
    if (!getAuth().currentUser) {
      throw redirect({
        to: '/login',
      })
    }
  },
  component: () => (
    <div className="h-full w-full flex items-center justify-center">
      <p className="text-3xl">Coming Soon</p>
    </div>
  )
})