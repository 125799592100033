import { createFileRoute, redirect } from '@tanstack/react-router';
import { getAuth } from 'firebase/auth';
import { Slider, Switch } from '@mui/material';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import * as queries from '../queries';
import React, { useState } from 'react';

export const Route = createFileRoute('/api_key')({
  beforeLoad: async ({ location }) => {
    if (!getAuth().currentUser) {
      throw redirect({
        to: '/login'
      });
    }
  },
  component: ApiKeyManager
});

export default function ApiKeyManager() {
  const [msg, setMsg] = useState('');
  const queryClient = useQueryClient();

  const getKeys = useQuery({
    queryKey: ['getAllKeys'],
    queryFn: queries.getAllKeys
  });
  const keys = getKeys.data;

  const { mutate: generateKeyMutation, isSuccess: generateKeySuccess } =
    useMutation({
      mutationFn: queries.generateKey,
      onSuccess: (data) => {
        queryClient.invalidateQueries({ queryKey: ['getAllKeys'] });
        setMsg(data.msg);
      }
    });

  const { mutate: deleteKeyMutation } = useMutation({
    mutationFn: queries.deleteKey,
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ['getAllKeys'] });
      setMsg(data.msg);
    }
  });

  const copyToClipboard = async (text: string) => {
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand('copy', true, text);
    }
  };

  return (
    <div className="mx-4">
      <div className="grid grid-cols-1 px-4 pt-6 xl:grid-cols-3 xl:gap-4 dark:bg-gray-900" />
      <div className="col-span-2">
        {/* Basic Agent Settings  */}
        <div className="p-4 mb-4 bg-white border border-gray-200 rounded-lg shadow-sm 2xl:col-span-2 dark:border-gray-700 sm:p-6 dark:bg-gray-800">
          <div>
            <h1 className="text-xl font-semibold p-2">Manage API Keys</h1>
            <button
              onClick={() => generateKeyMutation()}
              className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
              type="button">
              Generate New Key
            </button>
          </div>
          <div>
            {keys &&
              keys.map((key) => (
                <div
                  key={key}
                  className="justify-between items-center bg-gray-100 p-4 rounded-lg m-3 w-3/4">
                  <span className="break-words whitespace-normal m-4 p-2">
                    {key}
                  </span>
                  <div>
                    <button
                      onClick={() => copyToClipboard(key)}
                      className="m-2 bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">
                      Copy
                    </button>
                    <button
                      onClick={() => deleteKeyMutation(key)}
                      className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
                      Delete
                    </button>
                  </div>
                </div>
              ))}
          </div>
          <h3 className="text-xl font-semibold">{msg}</h3>
        </div>
      </div>
    </div>
  );
}
