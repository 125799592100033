import { createFileRoute, redirect } from '@tanstack/react-router'
import { getAuth } from "firebase/auth"

export const Route = createFileRoute('/settings')({
    beforeLoad: async ({ location }) => {
      if (!getAuth().currentUser) {
      throw redirect({
          to: '/login',
          search: {
              redirect: location.href,
          },
      })
  }},
  component: () => (
    <div className="h-full w-full flex items-center justify-center">
        <p className="text-3xl">Coming Soon</p>
    </div>
  )
})