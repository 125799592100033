import './App.css'
import './chatbot.css'

import { RouterProvider, createRouter } from '@tanstack/react-router'
import { TanStackRouterDevtools } from '@tanstack/router-devtools'
// Import the generated route tree
import { routeTree } from './routeTree.gen'
import {
  QueryClient,
  QueryClientProvider,
  useQuery,
} from '@tanstack/react-query'
import { useAuth } from './auth'

const queryClient = new QueryClient()

// Create a new router instance

export const router = createRouter({
  routeTree,
  context: {
    // auth will initially be undefined
    // We'll be passing down the auth state from within a React component
    auth: undefined!,
  },
})

// Register the router instance for type safety
declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router
  }
}

export default function App() {
  const auth = useAuth()
  return (
    <>
      <QueryClientProvider client={queryClient}>
        <RouterProvider router={router} context={{auth}}/>
      </QueryClientProvider>
    </>
  )
}