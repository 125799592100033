import { fbApp } from '../firebase';
import { useState } from 'react';
import { Link } from '@tanstack/react-router';
import {
  getAuth,
  signInWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup
} from 'firebase/auth';
import { useAuth } from '../auth';

import {
  createFileRoute,
  useNavigate,
  Navigate
} from '@tanstack/react-router';

export const Route = createFileRoute('/login')({
  component: Login
});

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate({ from: '/login' });
  const auth = useAuth();

  const handleLogin = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    const auth = getAuth(fbApp);
    try {
      await signInWithEmailAndPassword(auth, email, password);
      navigate({ to: '/voice_playground' });
      // Handle successful login, e.g., redirect to dashboard
    } catch (error: any) {
      setError(error.message);
    }
  };

  const signInWithGoogle = async () => {
    const auth = getAuth(fbApp);
    const provider = new GoogleAuthProvider();
    try {
      await signInWithPopup(auth, provider);
      navigate({ to: '/voice_playground' });
      // Handle successful Google sign-in, e.g., redirect to dashboard
    } catch (error: any) {
      setError(error.message);
    }
  };

  if (auth.isAuthenticated) {
    return <Navigate to="/voice_playground" />;
  }
  return (
    <div className="flex flex-col items-center justify-center px-6 pt-8 mx-auto md:h-screen pt:mt-0 dark:bg-gray-900">
      <a
        href="#"
        className="flex items-center justify-center mb-8 text-2xl font-semibold lg:mb-10 dark:text-white">
        <img
          alt="FlowBite Logo"
          className="mr-4 h-11"
          src="/adapt-logo-icon.png"
        />
        <span>Adapt</span>
      </a>
      <div className="w-full max-w-xl p-6 space-y-8 sm:p-8 bg-white rounded-lg shadow dark:bg-gray-800">
        <h2 className="text-2xl font-bold text-gray-900 dark:text-white">
          Sign in to Adapt
        </h2>
        {error && <p className="text-red-500">{error}</p>}
        <form action="#" className="mt-8 space-y-6">
          <div>
            <label
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              htmlFor="email">
              Your email
            </label>
            <input
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
              id="email"
              name="email"
              placeholder="name@company.com"
              required
              type="email"
            />
          </div>
          <div>
            <label
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              htmlFor="password">
              Your password
            </label>
            <input
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
              id="password"
              name="password"
              placeholder="••••••••"
              required
              type="password"
            />
          </div>
          {/* <div className="flex items-start">
            <div className="flex items-center h-5">
              <input
                aria-describedby="remember"
                className="w-4 h-4 border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 dark:focus:ring-primary-600 dark:ring-offset-gray-800 dark:bg-gray-700 dark:border-gray-600"
                id="remember"
                name="remember"
                required
                type="checkbox"
              />
            </div>
            <div className="ml-3 text-sm">
              <label
                className="font-medium text-gray-900 dark:text-white"
                htmlFor="remember">
                Remember me
              </label>
            </div>
            <a
              className="ml-auto text-sm text-primary-700 hover:underline dark:text-primary-500"
              href="#">
              Lost Password?
            </a>
          </div> */}
          <div className="grid grid-cols-2 xs:grid-cols-1 gap-4">
          <button
            className="w-full px-5 py-3 text-base font-medium text-center text-white bg-primary-700 rounded-lg hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 sm:w-auto dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
            onClick={(e) => handleLogin(e)}
            >
            Login to your account
          </button>
          <button
          onClick={signInWithGoogle}
          className="w-full px-5 py-3 text-base font-medium text-center text-white bg-green-700 rounded-lg hover:bg-green-800 focus:ring-4 focus:ring-green-300 sm:w-auto dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
          >
          Sign in with Google
          </button>
          </div>
          <div className="text-sm font-medium text-gray-500 dark:text-gray-400">
            Not registered?{' '}
            <Link to="/signup" className="text-primary-700 hover:underline dark:text-primary-500">
              Create account
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
}
