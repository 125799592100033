import React from 'react';
import { useMutation } from '@tanstack/react-query';
import * as queries from '../queries';
import { ChatMessage } from '../types/api';

const ActionProvider = ({ createChatBotMessage, setState, children, state }: any) => {
  const { mutate: postChatResponse, isSuccess: postChatResponseIsSuccess} = useMutation({
    mutationFn: queries.postChatResponse,
  })

  const handleMessage = (message: string) => {
    console.log("handleMessage", state)
    const messages: Array<ChatMessage> = state.messages.map((msg: any) => {
      return {text: msg.message, sender: msg.type === 'bot' ? 'bot' : 'human', timestamp: 10000000}
    })
    messages.push({ sender: 'human', text: message, timestamp: 10000000})

    postChatResponse({ chat_id: state.chatId, messages: messages}, {
      onSuccess: (data) => {
        const botMessages = data.map((msg) => createChatBotMessage(msg.text))

        setState((prev: any) => ({
          ...prev,
          messages: [...prev.messages, ...botMessages],
        }));
      }
    })
  };

  return (
    <div>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          actions: {
            handleMessage
          },
        });
      })}
    </div>
  );
};

export default ActionProvider;
