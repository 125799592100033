// @ts-nocheck

import React, { RefObject } from 'react';
import { isSafari } from 'react-device-detect';

const AudioVisualization = ({ analyser }: { analyser: AnalyserNode }) => {
  const canvasRef = React.useRef<HTMLCanvasElement>(null);

  React.useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;
    const ctx = canvas.getContext('2d');

    let dt = 0;
    const start = Date.now();

    function render() {
      if (!ctx || !canvas) return;
      dt = Date.now() - start;
      ctx.resetTransform();
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.globalAlpha = 1;
      ctx.translate(canvas.width / 2, canvas.height / 2);
      const max = Math.max(canvas.width, canvas.height);
      const grd = ctx.createLinearGradient(-max / 2, 0, max, 0);
      // grd.addColorStop(0, "hsl(190,100%,15%)");
      // grd.addColorStop(1, "hsl(270,100%,15%)");
      ctx.rotate(Math.PI / 4);
      ctx.fillStyle = grd;
      ctx.fillRect(-max, -max, max * 2, max * 2);
      ctx.rotate(-Math.PI / 4);
      if (!isSafari) {
        ctx.fillStyle = '#FFF';
        ctx.beginPath();
        ctx.arc(-50, -50, 170, 0, Math.PI * 2);
        ctx.filter = 'blur(100px)';
        ctx.globalAlpha = 0.4;
        ctx.fill();
      }
      ctx.filter = 'none';
      ctx.scale(1.3, 1.3);
      ctx.globalAlpha = 1;
      ctx.strokeStyle = '#FFF';
      ctx.lineWidth = 9;
      var rings = [
        {
          color: 'hsla(16, 100%, 61%, 1)',
          opacity: 0.3,
          distance: 70,
          variance: 1,
          innerRings: 1,
          lineWidth: 3,
          direction: -1,
          sections: 4
        },
      ];

      analyser.getByteFrequencyData(dataArray);
      const audioOffset = dataArray[Math.floor(0.1 * bufferLength)];

      function calculatePosition(ring, j, a, func) {
        if (!ctx) return;
        var dist = ring.distance;
        var sections = 17;
        var variance = ring.variance;
        variance += audioOffset / 200;
        dist += ((audioOffset / 5) * ring.distance) / 130;
        dist +=
          Math.cos(j * sections + dt / (170 - rings.indexOf(ring) * 12)) *
          5 *
          variance;
        dist += Math.sin(j * sections + dt / 1000) * 3 * variance;
        var angle = j;
        angle += a / 10;
        ctx[func](
          Math.cos(angle) * dist * ring.direction,
          Math.sin(angle) * dist * ring.direction
        );
      }

      var da = (Math.PI * 2) / 30;
      for (var i = 0; i < rings.length; i++) {
        ctx.globalAlpha = rings[i].opacity;
        ctx.strokeStyle = rings[i].color;
        for (var a = 0; a < rings[i].innerRings; a++) {
          ctx.beginPath();
          ctx.lineWidth =
            rings[i].lineWidth -
            (rings[i].lineWidth * a) / rings[i].innerRings / 2;
          calculatePosition(rings[i], 0, a, 'moveTo');
          for (var j = 0; j < Math.PI * 2; j += da) {
            calculatePosition(rings[i], j, a, 'lineTo');
          }
          calculatePosition(rings[i], 0, a, 'lineTo');
          ctx.stroke();
        }
      }
      ctx.resetTransform();
      ctx.filter = 'none';

      requestAnimationFrame(render);
    }

    var bufferLength = analyser.frequencyBinCount;
    var dataArray = new Uint8Array(bufferLength);

    function resize() {
      const container = canvas.parentElement;
      if (container) {
        canvas.width = container.clientWidth;
        canvas.height = container.clientHeight;
      }
    }

    window.onresize = resize;
    resize();
    render();
  }, []);

  return <canvas className="absolute" ref={canvasRef} />;
};

export default AudioVisualization;
