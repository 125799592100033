import { useMutation } from '@tanstack/react-query';
import { useState, useEffect } from 'react';
import { createFileRoute } from '@tanstack/react-router';
import * as queries from '../queries';
import { ChatRegisterResponse } from '../types/api';
import getConfig from '../chatbot/config';
import { z } from 'zod';
import { Chatbot } from 'react-chatbot-kit';
import MessageParser from '../chatbot/MessageParser';
import ActionProvider from '../chatbot/ActionProvider';

const ChatbotSearchSchema = z.object({
  agentId: z.string(),
  customerId: z.string()
});

type ChatbotSearch = z.infer<typeof ChatbotSearchSchema>;

export const Route = createFileRoute('/chatbot')({
  component: ChatbotEmbed,
  validateSearch: (search) => { 
    return ChatbotSearchSchema.parse(search)
  }
});

export function ChatbotEmbed() {
  const { agentId, customerId } = Route.useSearch<ChatbotSearch>();
  const [chatData, setChatData] = useState<ChatRegisterResponse>();

  const {
    mutate: registerChatMutate,
    isSuccess: isRegisterChatSuccess,
    isPending: isRegisterChatPending
  } = useMutation({
    mutationFn: queries.registerChat,
    onSuccess: (return_data, input_params) => {
      setChatData(return_data);
    }
  });

  useEffect(() => {
    registerChatMutate({
      agent_id: agentId,
      customer_id: customerId
    });
  }, []);

  return (
    <div className="h-full h-min-400">
      {isRegisterChatSuccess && chatData && (
        <Chatbot
          config={getConfig({ chatData: chatData })}
          messageParser={MessageParser}
          actionProvider={ActionProvider}
        />
      )}
    </div>
  );
}
