import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'

import * as Sentry from "@sentry/react";
import { AuthProvider } from './auth'

Sentry.init({
  dsn: "https://52709f44fd1963e5129516c46fecc6b8@o4506805333983232.ingest.sentry.io/4506809805045760",
  environment: import.meta.env.VITE_RUNTIME_ENV,
  integrations: [
    Sentry.browserTracingIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/api\.getadapt\.ai/],
});


ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <AuthProvider>
      <App />
    </AuthProvider>
  </React.StrictMode>
)
