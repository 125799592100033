import React from "react";
import { MessageIcon } from "./icons";

export const TableButton = ({ text, icon }: { text: string, icon: React.ReactNode }) => {
  return (
    <button
      className="text-gray-900 hover:text-white border border-gray-800 hover:bg-gray-900 focus:ring-4 dark:*:hover:text-white *:hover:text-white focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center me-2 dark:border-gray-600 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-800"
      type="button">
      {icon}
      {text}
    </button>
  );
};