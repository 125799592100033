import {
  createRootRouteWithContext,
  Outlet,
  useRouterState
} from '@tanstack/react-router';
import { useAuth } from '../auth';
import Sidebar from '../components/sidebar';
import Footer from '../components/footer';
import Navbar from '../components/navbar';
import { AuthContext } from '../auth';
import NavBarFL from '../components/navbar-fl';
import SidebarFL from '../components/sidebar-fl';

interface RouterAuthContext {
  auth: AuthContext;
}

export const Route = createRootRouteWithContext<RouterAuthContext>()({
  component: RootComponent
});

function RootComponent() {
  const auth = useAuth();
  const { location } = useRouterState();
  return (
    <>
      {location.pathname.startsWith('/chatbot') ? (
        <Outlet />
      ) : (
        <div className="w-full min-h-screen flex flex-col">
          <nav className="flex-initial z-50 w-full bg-white border-b border-gray-200 dark:bg-gray-800 dark:border-gray-700">
            <NavBarFL />
          </nav>

          <div className="w-full flex flex-row flex-auto h-full">
            {auth.isAuthenticated && (
              <aside
                aria-label="Sidebar"
                className="flex-initial z-40 w-64 h-screen pt-10 transition-transform -translate-x-full bg-white border-r border-gray-200 sm:translate-x-0 dark:bg-gray-800 dark:border-gray-700"
                id="logo-sidebar">
                <SidebarFL />
              </aside>
            )}
            <div className="flex-auto bg-gray-50">
              <div className="dark:border-gray-700 h-full">
                <Outlet />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
