import { createFileRoute, redirect } from '@tanstack/react-router';
import { getAuth } from 'firebase/auth';
import * as React from 'react';
import { useState, useEffect } from 'react';
import * as queries from '../queries';
import { useQuery } from '@tanstack/react-query';


export const Route = createFileRoute('/recording/$callId')({
  beforeLoad: async ({ location }) => {
    if (!getAuth().currentUser) {
      throw redirect({
        to: '/login'
      });
    }
  },
  loader: ({ params: { callId } }) => queries.getRecording(callId),
  component: RecordingComponent
});

export function RecordingComponent() {
  const recording = Route.useLoaderData<Blob>();
  return (

    <div className="flex items-center mt-6">
      <div className="px-4 pt-6" />
        <div>
          <div>
            <button onClick={() => window.history.back()} className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
              Go Back
            </button>
          </div>
          
          { recording ? (
          <>
              <div className="p-4 mb-4 bg-white border border-gray-200 rounded-lg shadow-sm 2xl:col-span-2 dark:border-gray-700 sm:p-6 dark:bg-gray-800">
                <h2 className="mb-4 text-xl font-bold text-gray-900 dark:text-white">
                  This feature is still in Beta and might not work properly.
                </h2>
                <audio controls>
                  <source src={URL.createObjectURL(recording)} type="audio/mpeg" />
                    Your browser does not support the audio element.
                </audio>        
              </div>
          </>
        ) : (
          <h2>
            Recording Not Found.
          </h2>
        )
      }
      </div>
      </div>
  );
}
