import { createChatBotMessage } from 'react-chatbot-kit';
import { ChatRegisterResponse } from '../types/api';


const getConfig = ({ chatData: chatData } : { chatData: ChatRegisterResponse }) => {
  const config = {
    initialMessages: [createChatBotMessage(chatData.greeting[0].text, {})],
    botName: "Multilink Bot",
    state: {
      chatId: chatData.conversation_id
    }
  }
  return config
};

export default getConfig
